import React, { Fragment, useState, useCallback } from 'react';
import {Snackbar, TextField} from '@material-ui/core';
import ConfirmDialog, { ConfirmDialogProps } from '../ConfirmDialog';
import AddSettingDialog from './AddSettingDialog';
import SettingsTable from './SettingsTable';
import SettingsComponent from './SettingsComponent';
import {getAll, getOne, post} from "../../HTTPClients/RecipeApp/plants";
import CustomSnackbarContent from "../CustomSnackbarContent";

const Plants: React.FC<{ PrevComponent: React.FC<{ SearchElement?: JSX.Element }> }> = ({ PrevComponent }) => {
  const [dialogProps] = useState({ open: false } as Omit<ConfirmDialogProps, 'title' | 'content'>);
  const [filter, setFilter] = useState('');
  const [snackbar, setSnackbar] = useState(undefined as { message: string, variant: 'success' | 'warning' | 'error' | 'info' } | undefined);

  const getRows = useCallback(async () => {
    let response=await getAll();
    return response.data.data;
  }, []);

  const getRow = useCallback(async (plantId: string) => {
    let response=await getOne(Number(plantId));
    return response.data.data;
  }, []);

  const handleSave = useCallback(async (plant: any, plants: any[] | undefined, saveCallback: (plants?: any[]) => void) => {
    if (plants && plant.id) {
      post(plant).then(
          function(response) {
            const index = plants.findIndex(f => f.id === plant.id);
            index >= 0 && (plants[index] = plant);
            saveCallback(plants);
          }
      ).catch(
          function (error) {
            if(error.response===undefined)
              console.log(error);
            else
            if(error.response.data==='not_unique') notUniqueSnackbar();
          }
      )
    } else if (plants) {
      post(plant).then(
          function(response) {
            plants.push({ ...plant, id: response.data.data.id });
            saveCallback(plants);
          }
      ).catch(
          function (error) {
            if(error.response===undefined)
              console.log(error);
            else
            if(error.response.data==='not_unique') notUniqueSnackbar();
          }
      )
    }
  },[]);

  function notUniqueSnackbar() {
    setSnackbar({
      variant: 'warning',
      message: 'Deze centrale bestaat al'
    })
  }

  const handleDelete = useCallback((plant: any, deleteCallback: (plant: any) => void) => {
    noDeleteSnackbar();
/*
    deleteAllowed(plant.id).then(
        function(response) {
          if(response.data.allowed) {
            confirmDelete({
              open: true,
              onCancel: () => confirmDelete({ open: false }),
              onConfirm: async () => {
                remove(plant.id).then(
                    function(response) {
                      deleteCallback(plant);
                    }
                ).finally(
                    function() { confirmDelete({ open: false }); }
                )
              }
            });
          } else {
            noDeleteSnackbar();
            return;
          }
        }
    )*/
  },[]);

  function noDeleteSnackbar() {
    setSnackbar({
      variant: 'warning',
      message: 'Deze centrale mag niet verwijderd worden'
    })
  }

  const SearchElement = <TextField placeholder="Centrale zoeken..." value={filter} onChange={e => setFilter(e.target.value)} variant="outlined" margin="dense" />;

  return (
    <SettingsComponent
      PrevComponent={PrevComponent}
      SearchElement={SearchElement}
      path="/settings/plants"
      getRows={getRows}
      getRow={getRow}
    >{({ rows, row, addDialogOpen, onEdit, onSave, onChange, onDelete, onCancelAdd }) => (
      <Fragment>
        {rows && <SettingsTable
          columns={[{ name: 'name', title: 'Centrale naam' }]}
          rows={rows.filter(f => f.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0)}
          onEdit={(plant: any) => plant.id && onEdit(plant.id)}
          onDelete={(plant: any) => handleDelete(plant, onDelete)}
        />}
        {!row ? null : (
          <AddSettingDialog
            title={row.id ? 'Beton centrale bewerken' : 'Beton centrale toevoegen'}
            open={addDialogOpen}
            onClose={() => onCancelAdd()}
            onSave={() => handleSave(row, rows, onSave)}
          >
            <TextField
              label="Beton centrale naam"
              value={row.name}
              onChange={e => onChange({ ...row, name: e.target.value })}
              variant="outlined"
              margin="normal"
              fullWidth={true}
            />
          </AddSettingDialog>
        )}
        <ConfirmDialog {...dialogProps} title="Beton centrale verwijderen" content="Weet u zeker dat u deze beton centrale wilt verwijderen?" />
        <Snackbar open={Boolean(snackbar)} onClose={() => setSnackbar(undefined)} autoHideDuration={6000}>
          <CustomSnackbarContent
              variant={snackbar ? snackbar.variant : undefined}
              message={snackbar ? snackbar.message : undefined}
          />
        </Snackbar>
      </Fragment>
    )}
    </SettingsComponent>
  )
}

export default Plants;
