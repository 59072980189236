export const Settings = {
	  environment: 'development', // 'development' or 'production'
	  company: 'abc_prod',
	  URLs: {
		client: '',
		server: 'https://abc.receptuur.backend.jbmsoftware.nl',
		planningApp: {
		  recipeApp: {
			root: 'https://abc.backend.jbmsoftware.nl/recipe-app/',
			loginSegment: 'post/login'
		  }
		},
		labApp: 'https://abc.lab.jbmsoftware.nl',
		orderApp: 'https://abc.jbmsoftware.nl',
		appLogin:'https://abc.backend.jbmsoftware.nl/recipe-app/post/login'
	  }
	}
