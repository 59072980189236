import React, {useCallback, useEffect, useRef, useState} from 'react';
import StepContent from '@material-ui/core/StepContent';
import { StepContentComponent } from './AddResourceSteps';
import { Grid, TextField } from '@material-ui/core';
import CurrencyTextField from '../CurrencyTextField';
import useAuthorized from '../../useAuthorized';
import { ResourceUnion } from '../../types';
import {Alert} from "@material-ui/lab";

const ResourceInformationStep: StepContentComponent<ResourceUnion> = ({ onChange, onSave, resources, resource, ...stepContentProps }) => {
  const disabled = !useAuthorized(['update:resources_info'])
  const hidden = !useAuthorized(['read:resources_price'])
  const firstInputRef = useRef<HTMLInputElement>(null);
  const [isUnique, setUnique]= useState(true);

  const checkIsUnique = useCallback((code: any) => {
    setUnique(true);

    if(!resources) return true;
    if(!code) return true;

    for(let r of resources) {
      if(r.articleCode===null)
        continue;
      if(r.articleCode.toUpperCase()===code.toUpperCase() && (!resource.hasOwnProperty('id') || r.id!==resource.id)) {
        console.log('niet');
        setUnique(false);
        return false;
      }
    }

    return true;
  },[resource,resources]);

  useEffect(() => {
    !resource.id && stepContentProps.active && firstInputRef.current && firstInputRef.current.focus();
    checkIsUnique(resource.articleCode);
  }, [stepContentProps.active, resource.id]);
  return (
    <StepContent {...stepContentProps}>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12}>
          <TextField
            label="Leverancier"
            value={resource.supplier || ''}
            onChange={e => onChange({ ...resource, supplier: e.target.value })}
            variant="outlined"
            fullWidth={true}
            inputRef={firstInputRef}
            disabled={disabled}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <TextField
            label="Merknaam"
            value={resource.brand || ''}
            onChange={e => onChange({ ...resource, brand: e.target.value })}
            variant="outlined"
            fullWidth={true}
            disabled={disabled}
          />
        </Grid>
        <Grid item={true} lg={6}>
          <TextField
            label="Artikel code"
            value={resource.articleCode || ''}
            onChange={e => { checkIsUnique(e.target.value); onChange({ ...resource, articleCode: e.target.value })}}
            variant="outlined"
            fullWidth={true}
            required={true}
            disabled={disabled}
          />
          {!isUnique && <Alert severity="warning">Geef svp een unieke artikel code op</Alert>}
        </Grid>
        {!hidden && <Grid item={true} lg={6}>
          <CurrencyTextField
            label="Basisprijs (p/t)"
            value={resource.price || null }
            onChange={(e: any) => onChange({ ...resource, price: e.target.value })}
            fullWidth={true}
            required={true}
            disabled={disabled}
          />
        </Grid>}
      </Grid>
    </StepContent>
  )
}

export default ResourceInformationStep;
