import React, { useState, useCallback, ChangeEvent, useEffect, useRef } from 'react';
import StepContent from '@material-ui/core/StepContent';
import { StepContentComponent } from './AddResourceSteps';
import {ResourceUnion} from '../../types';
import { FormControl, InputLabel, Select, Chip, MenuItem, Checkbox, ListItemText, makeStyles, Theme } from '@material-ui/core';
import {getAll} from '../../HTTPClients/RecipeApp/plants';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme: Theme) => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '-8.5px 0'
  },
  chip: {
    margin: 2,
  }
}));

const ResourcePlantsStep: StepContentComponent<ResourceUnion> = ({ onChange, onSave, resource, ...stepContentProps }) => {
  const [labelWidth, setLabelWidth] = useState(0)
  const [plants, setPlants] = useState([] as any[])
  const classes = useStyles()
  const inputLabel = useRef<HTMLLabelElement>(null)
  const firstInputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    getAll().then(
        function(response) {
          setPlants(response.data.data)
        }
    )
  },[setPlants])

  useEffect(() => {
    try {
      !resource.id && stepContentProps.active && firstInputRef.current && firstInputRef.current.focus();
    } catch (e) {
      console.log(e);
    }
    inputLabel.current && setLabelWidth(inputLabel.current!.offsetWidth);
  }, [stepContentProps.active, resource.id]);

  const handleChange = useCallback((e: ChangeEvent<{ value: unknown }>) => {
    onChange({ ...resource, plants: plants.filter(p => p.id && (e.target.value as unknown as number[]).indexOf(p.id) >= 0) });
  }, [onChange, plants, resource]);

  return (
    <StepContent {...stepContentProps}>
      <FormControl fullWidth={true} variant="outlined">
        <InputLabel htmlFor="select-multipe-plants" required={true} ref={inputLabel}>Selecteer centrale(s)</InputLabel>
        <Select
          labelWidth={labelWidth}
          multiple={true}
          value={resource.plants.map(p => p.id)}
          onChange={handleChange}
          inputRef={firstInputRef}
          inputProps={{
            id: 'select-multipe-plants',
          }}
          renderValue={selected => (
            <div className={classes.chips}>
              {(selected as number[]).map(id => {
                const plant = plants.find(p => p.id === id);
                return (
                  <Chip key={id} label={plant && plant.name} className={classes.chip} />
                )
              })}
            </div>
          )}
          MenuProps={MenuProps}
        >
          {plants.map(plant => (
            <MenuItem key={plant.id} value={plant.id}>
              <Checkbox checked={resource.plants.map(p => p.id).indexOf(plant.id) > -1} />
              <ListItemText primary={plant.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </StepContent>
  )
}

export default ResourcePlantsStep;
