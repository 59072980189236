import {Settings} from "../../config/settings";
const axios = require('axios').default;

const axiosClient = axios.create({
	baseURL: Settings.URLs.server+'/rest',
  headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
    Authentication: localStorage.getItem('token')
	} /*
  baseURL: Settings.URLs.server+'/recipe-app',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    authentication: localStorage.getItem('token'),
    Company: Settings.company
  }*/
});

export default axiosClient;
