import React, { ReactText, Fragment, useState, useCallback, useEffect } from 'react'
import { Column, TableFilterRow } from '@devexpress/dx-react-grid'
import { Button, Switch, Icon, Typography, TextField, MenuItem, Snackbar, Box, TableCell, Card, FormGroup, FormControlLabel } from '@material-ui/core'
import { ResourceType, Recipe, RecipeStatus } from '../types'
import { withRouter, RouteComponentProps, Redirect } from 'react-router-dom'
import DataTable from '../components/DataTable'
import ConfirmDialog, { ConfirmDialogProps } from '../components/ConfirmDialog'
import resourceTypes from '../resourceTypes'
import ResourceDetailDialog from '../components/resources/ResourceDetailDialog'
import AddResourceDialog from '../components/resources/AddResourceDialog'
import CustomSnackbarContent from '../components/CustomSnackbarContent'
import { format } from 'react-numberinput-formatter'
import { grey, indigo } from '@material-ui/core/colors'
import {updateRecipe, getErrors, getComputations, getVolume} from '../computed/recipeComputations'
import { diff } from 'deep-object-diff'
import RecipeChangesDialog, { RecipeChangesDialogProps } from '../components/resources/RecipeChangesDialog'
import useAuthorized from '../useAuthorized'
import { sanitizeRecipe, sanitizeResource } from '../sanitize'
import { merge } from 'lodash'
import {post as postResource, getAll, getOne, toggleActive, remove} from "../HTTPClients/RecipeApp/resources/resources";
import {getOne as getRecipe, post as postRecipe, getWithResource} from "../HTTPClients/RecipeApp/recipes/recipes";
import {calculateAdditions} from "../components/recipes/RecipeDialogContent";

const ResourceNameCell: React.FC<{ row: any, onEdit: (resource: any) => void, onActivate: (resource: any) => void, onDeactivate: (resource: any) => void, onDelete: (resource: any) => void }> = ({ row, onEdit, onActivate, onDeactivate, onDelete }) => {
  const canDelete = useAuthorized(['delete:resources'])
  const canEdit = useAuthorized(['update:resources'])
  return (
    <TableCell style={{ overflow: 'show', position: 'relative' }}>
      {/* @ts-ignore */}
      <Box display="flex" alignItems="center" height="100%"><span style={{ paddingRight: 8 }}>{row.name}</span></Box>
      {/* @ts-ignore */}
      <Box position="absolute" top={0} display="flex" bgcolor={grey[100]} alignItems="center" className="row-hover-buttons">
        <span style={{ padding: '12px 8px 12px 0', whiteSpace: 'nowrap' }}>{row.name}</span>
        {/* @ts-ignore */}
        <Box display="flex">
          {canEdit && <Button onClick={() => onEdit(row)} size="small" variant="outlined" color="primary" style={{ marginLeft: 8 }}><Icon fontSize="small">create</Icon>&nbsp;&nbsp;Bewerken</Button>}
          {row.active && canEdit && <Button onClick={() => onDeactivate(row)} size="small" variant="outlined" color="primary" style={{ marginLeft: 8 }}>Deactiveren</Button>}
          {!row.active && canEdit && <Button onClick={() => onActivate(row)} size="small" variant="outlined" color="primary" style={{ marginLeft: 8 }}>Activeren</Button>}
          {canDelete && <Button onClick={() => onDelete(row)} size="small" variant="outlined" color="primary" style={{ marginLeft: 8 }}><Icon fontSize="small">delete</Icon>&nbsp;&nbsp;Verwijderen</Button>}
        </Box>
      </Box>
    </TableCell>
  )
}

const ResourceIcon: React.FC<{ type: ResourceType }> = ({ type }) => {
  const resourceType = resourceTypes[type as keyof typeof resourceTypes]
  return (
    <div style={{ background: resourceType.color, padding: 2, borderRadius: 3, margin: '-7px 0', width: 24, height: 24 }}>
      <img
        src={resourceType.icon}
        alt={resourceType.title}
        title={resourceType.title}
        style={{ width: 20, height: 20 }}
      />
    </div>
  )
}

const ResourceTypeFilterCell: React.FC<TableFilterRow.CellProps> = ({ column, filter, onFilter }) => {
  return (
    <TextField
      margin="dense"
      select={true}
      variant="outlined"
      value={filter ? filter.value : ''}
      onChange={e => onFilter({ columnName: column.name, value: e.target.value })}
    >
      <MenuItem value="">Alle</MenuItem>
      {Object.keys(resourceTypes).map(k => <MenuItem key={k} value={k}>
        {resourceTypes[k as keyof typeof resourceTypes].title}
      </MenuItem>)}
    </TextField>
  )
}

const order = [ResourceType.Cement, ResourceType.Filler, ResourceType.Addition, ResourceType.Excipient, ResourceType.Extra, ResourceType.Water];

const AddNewComponent: React.FC<{ onClick: () => void }> = ({ onClick }) => <Button onClick={onClick} variant="contained" color="primary"><Icon>add_circle</Icon>&nbsp;&nbsp;Nieuwe grondstof</Button>;

const ToolbarSelectionComponent: React.FC<{ selection: Array<number | string | ReactText>, onDelete: () => void }> = ({ selection, onDelete }) => (
  <Fragment>
    <Typography variant="subtitle2">{selection.length + (selection.length === 1 ? ' geselecteerde grondstof' : ' geselecteerde grondstoffen')}</Typography>&nbsp;&nbsp;
    <Button onClick={onDelete} color="inherit"><Icon>delete</Icon>&nbsp;&nbsp;Verwijderen</Button>
  </Fragment>
)

const Resources: React.FC<RouteComponentProps<{ resourceId?: string, resourceType?: string }>> = ({ history, match }) => {
  const [loadingDialog, setLoadingDialog] = useState(false);
  const [snackbar, setSnackbar] = useState(undefined as { message: string, variant: 'success' | 'warning' | 'error' | 'info' } | undefined);
  const [open, setOpen] = useState(false);
  const [resources, setResources] = useState([] as any[])
  const [active, setActive] = useState(true);
  const [selected, setSelected] = useState(undefined as any | undefined);
  const [resourceType, setResourceType] = useState(undefined as ResourceType | undefined);
  const [dialogProps, confirmDelete] = useState({ open: false } as Omit<ConfirmDialogProps, 'title' | 'content'>);
  const [confirmClosedialogProps, confirmClose] = useState({ open: false } as Omit<ConfirmDialogProps, 'title' | 'content'>)
  const [recipeChangesDialogProps, openRecipeChangesDialog] = useState({ open } as RecipeChangesDialogProps)
  const authorized = useAuthorized(['read:resources'])
  const authorizedUpdate = useAuthorized(['update:resources'])
  const canAdd = useAuthorized(['create:resources'])

  React.useEffect(() => {
    getAll(true).then(
        function(response) {
          setResources(response.data.data.sort( (a: any, b: any) => order.indexOf(a.type) - order.indexOf(b.type)) );
        }
    )
  },[]);

  useEffect(() => {
    if ((match.path === '/resource/add/:resourceType?' || match.params.resourceId)) {
      setOpen(true);
      if (match.params.resourceId && !loadingDialog && (!selected || selected.id !== Number(match.params.resourceId))) {
        setLoadingDialog(true);
        resources && resources.length > 0 && setSelected(resources.find((resource: any) => resource.id === Number(match.params.resourceId)));
        getOne(Number(match.params.resourceId)).then(
            function(response) {
              setSelected(response.data.data);
              setLoadingDialog(false);
            }
        )
      }
      if (match.params.resourceType) {
        setResourceType(match.params.resourceType.toLowerCase() as ResourceType);
      }
    } else if (!loadingDialog && open) {
      setOpen(false);
      setSelected(undefined);
      setResourceType(undefined);
    }
  }, [match, open, loadingDialog, selected, resources]);

  const handleAddClick = useCallback(() => history.push('/resource/add'), [history]);

  const handleSave = useCallback(async (resource: any) => {
    if (!resource.id && resources) {
      postResource(sanitizeResource(resource)).then(
          function(response) {
            getAll(active).then(
                function(response) {
                  setResources(response.data.data.sort( (a: any, b: any) => order.indexOf(a.type) - order.indexOf(b.type)) );
                }
            )
          }
      )
    } else {
      const response=await getWithResource(resource.id);
      const recipes = response.data.data;
      let showRecipeChanges = false
      const diffs: Array<{ diff: any, oldValues: any, recipe: Recipe, errors: string[], oldStatus: RecipeStatus }> = []
      recipes.forEach(r => {
        const { diff, oldValues, recipe } = getDiff(r, resource)
        const isDiff = Object.keys(diff).length > 0
        !showRecipeChanges && isDiff && (showRecipeChanges = true)
        const keys = Object.keys(diff)
        const errors = getErrors(recipe).filter(e => keys.indexOf(e) >= 0)
        isDiff && diffs.push({ diff, oldValues, recipe: { ...recipe, status: errors.length > 0 ? RecipeStatus.Error : RecipeStatus.Changed }, errors, oldStatus: recipe.status })
      })
      if (showRecipeChanges && resources) {
        const proceed = await new Promise(async resolve => {
          openRecipeChangesDialog({
            open: true,
            diffs,
            onPrevious: () => {
              openRecipeChangesDialog({ open: false })
              resolve(false)
            },
            onCancel: () => {
              confirmClose({
                open: true,
                onCancel: () => confirmClose({ open: false }),
                onConfirm: () => {
                  openRecipeChangesDialog({ open: false })
                  history.push('/resource')
                  confirmClose({ open: false })
                }
              })
            },
            onSave: async (recipes: Recipe[]) => {
              const remaining = Array(recipes.length).fill(0)
              while (recipes.length > 0) {
                const promisses: Array<Promise<any>> = []
                recipes.splice(0, 10).forEach(recipe => {
                  promisses.push(new Promise<void>(async resolve => {
                    const result=await getRecipe(recipe.id);

                    const { ingredients, ...rest } = result.data.data as Recipe
                    ingredients.forEach(ingredient => merge(ingredient, recipe.ingredients.find(i => i.resource.id === ingredient.resource.id)))
                    const data = merge(rest, recipe, { ingredients })
                    try {
                      let newData=sanitizeRecipe(data);
                      newData['id']=recipe.id;

                      await postRecipe(newData);
                      remaining.splice(0, 1)
                    } catch (e) {
                      // TODO: what to do with errors...try again later?
                    }
                    resolve()
                  }))
                })
                console.log('remaining', remaining.length)
                await Promise.all(promisses)
              }
              resolve(true)
            }
          })
        })
        if (!proceed) return
      }
      await postResource(sanitizeResource(resource));
      const index = resources.findIndex((r: any) => r.id === resource.id);
      index >= 0 && (resources[index] = { ...resource });
    }
    openRecipeChangesDialog({ open: false })
    history.push('/resource');
    setSnackbar({
      variant: 'success',
      message: 'Succesvol opgeslagen!'
    });
  }, [history, active, resources]);

  const handleActivate = useCallback(async(resource: any) => {
    if(resource.active) return;
    await toggleActive(resource.id);
    getAll(active).then(
        function(response) {
          setResources(response.data.data.sort( (a: any, b: any) => order.indexOf(a.type) - order.indexOf(b.type)) );
        }
    )
  },[active, setResources]);

  const handleDeactivate = useCallback(async(resource: any) => {
    if(!resource.active) return;
    await toggleActive(resource.id);
    getAll(active).then(
        function(response) {
          setResources(response.data.data.sort( (a: any, b: any) => order.indexOf(a.type) - order.indexOf(b.type)) );
        }
    )
  },[active]);

  const handleDelete = useCallback((ids: number[]) => {
    confirmDelete({
      open: true,
      onCancel: () => confirmDelete({ open: false }),
      onConfirm: async () => {
        const promisses: Promise<any>[] = [];
        ids.forEach(id => promisses.push(new Promise(async resolve => {
          try {
            await remove(id);
            resolve(id);
          } catch (error) {
            resolve(false);
          }
        })));
        const delIds = await Promise.all(promisses)
        console.log('DEL', delIds)
        if (delIds < ids) {
          setSnackbar({
            variant: 'error',
            message: 'Niet alle grondstoffen konden verwijderd worden!'
          });
        } else {
          setSnackbar({
            variant: 'success',
            message: 'Grondstoffen verwijderd!'
          })
        }
        confirmDelete({ open: false });

        getAll(active).then(
            function(response) {
              setResources(response.data.data);
            }
        )
      }
    });
  }, [active, setResources]);

  const handleEditClick = useCallback((resource: any) => {
    history.push('/resource/' + resource.id);
  }, [history]);

  const handleClose = useCallback(() => {
    history.push('/resource');
  }, [history]);

  const handleActive = useCallback((active: any) => {
    setActive(active);
    getAll(active).then(
        function(response) {
          setResources(response.data.data.sort( (a: any, b: any) => order.indexOf(a.type) - order.indexOf(b.type)) );
        }
    )
  },[setActive, setResources]);

  const renderAddNewComponent = useCallback(() => <AddNewComponent onClick={handleAddClick} />, [handleAddClick]);
  const renderToolbarSelectionComponent = useCallback(({ selection }: any) => <ToolbarSelectionComponent onDelete={() => handleDelete(selection)} selection={selection} />, [handleDelete]);
  const renderResourceNameCell = useCallback((props: any) => <ResourceNameCell {...props}
      onEdit={handleEditClick} onActivate={handleActivate} onDeactivate={handleDeactivate} onDelete={resource => resource.id && handleDelete([resource.id])} />, [handleEditClick, handleActivate, handleDeactivate, handleDelete]);

  const columns: Array<Column & { width?: number, filteringEnabled?: boolean, renderCellComponent?: React.FC<{ row: any }>, filterCellComponent?: React.FC<TableFilterRow.CellProps> }> = [
    { title: 'Type', name: 'type', width: 85, renderCellComponent: ({ row }) => <TableCell><ResourceIcon type={row.type} /></TableCell>, filterCellComponent: ResourceTypeFilterCell },
    { title: 'Grondstofnaam', name: 'name', renderCellComponent: renderResourceNameCell },
    { title: 'Beton centrales', name: 'plants', getCellValue: (resource: any) => resource.plants.map(p => p.name).join(', ') },
    { title: 'Artikel code', width: 125, name: 'articleCode' },
    { title: 'Volumiekemassa (kg/m³)', width: 210, name: 'density', getCellValue: (resource: any) => format(resource.density, { maximumFractionDigits: 1 }) },
    { title: 'Alkali gehalte (%)', width: 160, name: 'alkaliPercentage', getCellValue: (resource: any) => format(resource.alkaliPercentage || 0, { maximumFractionDigits: 4 }) },
    { title: 'Chloridegehalte (%)', width: 170, name: 'chloridePercentage', getCellValue: (resource: any) => format(resource.chloridePercentage || 0, { maximumFractionDigits: 4 }) },
    { title: 'C-waarde', width: 110, name: 'cvalue', getCellValue: (resource: any) => format(resource.cvalue || 0, { maximumFractionDigits: 2 }) }
  ];
  return !authorized || (!canAdd && !selected && open) || (!authorizedUpdate && selected && open) ?
      /* @ts-ignore */
      <Redirect to="/unauthorized" /> : (
    <Fragment>
      {/* @ts-ignore */}
      <Box position="absolute" display="block" width="100%" bgcolor={indigo[50]} height={280} zIndex={1} />
      {/* @ts-ignore */}
      <Box padding={0} display="flex" flexDirection="column" height="100%">

        {!selected && <FormGroup className={'activeSwitch'}>
          <FormControlLabel
              control={<Switch defaultChecked={true} onChange={e => handleActive(e.target.checked)} />} label="Actief" />
        </FormGroup>}
        <Card style={{ flex: 1, zIndex: 2, overflow: 'auto' }}>
          <DataTable
            title="Grondstoffen"
            rows={resources}
            columns={columns}
            withSelection={true}
            withSearch={true}
            withFiltering={true}
            withSorting={true}
            AddNewComponent={canAdd ? renderAddNewComponent : undefined}
            ToolbarSelectionComponent={renderToolbarSelectionComponent}
            actions={[]}
          />
          {selected ?
              <ResourceDetailDialog open={open} resources={resources} resource={selected} onClose={handleClose} onSave={handleSave} /> :
              <AddResourceDialog open={open} resources={resources} resourceType={resourceType} onSave={handleSave} onClose={handleClose} />
          }
          <RecipeChangesDialog {...recipeChangesDialogProps} />
          <ConfirmDialog {...confirmClosedialogProps} title="Er zijn niet opgeslagen aanpassingen" content="Weet u zeker dat u wilt afsluiten zonder op te slaan?" />
          <ConfirmDialog {...dialogProps} title="Grondstoffen verwijderen" content="Weet u zeker dat u deze grondstoffen wilt verwijderen?" />
          <Snackbar open={Boolean(snackbar)} onClose={() => setSnackbar(undefined)} autoHideDuration={6000}>
            <CustomSnackbarContent
              variant={snackbar ? snackbar.variant : undefined}
              message={snackbar ? snackbar.message : undefined}
            />
          </Snackbar>
        </Card>
      </Box>
    </Fragment>
  )
}

export default withRouter(Resources);

function getDiff(recipe: Recipe, resource: any) {
  recipe.ingredients=getLeadingAdditionPercentages(recipe.ingredients);
  const oldValues = getComputations({ ...recipe })

  const newRecipe = updateRecipe(recipe, [resource])
  newRecipe.ingredients=getLeadingAdditionPercentages(newRecipe.ingredients);
  const newValues = getComputations(newRecipe)

  return { diff: diff(oldValues, newValues), oldValues, recipe: newRecipe }
}

export function getLeadingAdditionPercentages(ingredients: any[]) {
  let totalAdditionVolume = 0
  let totalSandVolume = 0
  let totalGravelVolume = 0

  for (let ingredient of ingredients){
    if(ingredient.resource.type !== 'addition')
      continue;

    const volume=getVolume([ingredient])
    if(ingredient.resource.isSand)
      totalSandVolume += volume
    else
      totalGravelVolume += volume
    totalAdditionVolume += volume
  }

  let sandPercentage = Math.round((totalSandVolume / totalAdditionVolume) * 100)
  let gravelPercentage = Math.round(100 - sandPercentage)

  for(let ingredient of ingredients) {
    if(ingredient.resource.type!=='addition' || ingredient.percentage!==100)
      continue;
    if(ingredient.resource.isSand) {
      ingredient.percentage=sandPercentage;
    } else {
      // This must be the primary gravel (1 sand / 1 gravel)
      ingredient.percentage=gravelPercentage;
    }
  }

  return ingredients;
}

