export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
};

export type Addition = {
  name: Scalars['String'];
  type: ResourceType;
  articleCode?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  supplier?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  density: Scalars['Float'];
  chloridePercentage?: Maybe<Scalars['Float']>;
  alkaliPercentage?: Maybe<Scalars['Float']>;
  plants: Array<Plant>;
  active: Boolean;
  cvalue?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
  absorption?: Maybe<Scalars['Float']>;
  moisture?: Maybe<Scalars['Float']>;
  isSand?: Maybe<Scalars['Boolean']>;
  sieveTest?: Maybe<SieveTest>;
  gradingCurve?: Maybe<GradingCurve>;
};

export type Attest = {
  attestNumber: Scalars['String'];
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
};

export type AttestInput = {
  attestNumber: Scalars['String'];
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
};

export type BaseAttest = {
  attestNumber: Scalars['String'];
  expirationDate?: Maybe<Scalars['DateTime']>;
};

export type BaseChlorideClass = {
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  maxChloride: Scalars['Float'];
};

export type BaseConsistencyClass = {
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  minRequirement: Scalars['Float'];
  maxRequirement?: Maybe<Scalars['Float']>;
  minDeviation?: Maybe<Scalars['Float']>;
  maxDeviation?: Maybe<Scalars['Float']>;
  maxDeviationsAllowed?: Maybe<Scalars['Int']>;
  consistencyTestTypes: Array<ConsistencyTestTypeInput>;
};

export type BaseConsistencyTestType = {
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  parameters: Array<ConsistencyTestParameterInput>;
};

export type BaseEnvironmentClass = {
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  minCement: Scalars['Float'];
  maxWbf: Scalars['Float'];
  requirementMax: Scalars['Float'];
  deviationMax: Scalars['Float'];
  maxDeviationsAllowed: Scalars['Int'];
  minimumAirPercentages?: Maybe<Array<MinimumAirPercentageInput>>;
  encroachment: EnvironmentClassEncroachment;
};

export type BaseFamily = {
  name: Scalars['String'];
};

export type BaseGradingCurve = {
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type: SieveSetType;
  sieveSteps: Array<GradingCurveStepInput>;
};

export type BasePlant = {
  name: Scalars['String'];
};

export type BaseSieveSet = {
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type: SieveSetType;
  sieveSteps: Array<SieveStepInput>;
};

export type BaseSieveSize = {
  code?: Maybe<Scalars['String']>;
  size: Scalars['Float'];
};

export type BaseStrengthClass = {
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  cilinderPressureStrength: Scalars['Float'];
  cubePressureStrength: Scalars['Float'];
  bendTensileStrength: Scalars['Float'];
  samplePerVolume: Scalars['Int'];
  sampleMinimum: Scalars['Int'];
  sampleMaximum: Scalars['Int'];
};

export type BaseTypeOfWork = {
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  maxChloride: Scalars['Float'];
  minPercentageFine?: Maybe<Scalars['Float']>;
};

export type Cement = {
  name: Scalars['String'];
  type: ResourceType;
  articleCode?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  supplier?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  density: Scalars['Float'];
  chloridePercentage?: Maybe<Scalars['Float']>;
  alkaliPercentage?: Maybe<Scalars['Float']>;
  plants: Array<Plant>;
  active: Boolean;
  cvalue?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
  strengthWeek?: Maybe<Scalars['Float']>;
  strengthNorm: Scalars['Float'];
};

export type CementInput = {
  name: Scalars['String'];
  type: ResourceType;
  articleCode?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  supplier?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  density: Scalars['Float'];
  chloridePercentage?: Maybe<Scalars['Float']>;
  alkaliPercentage?: Maybe<Scalars['Float']>;
  plants: Array<PlantInput>;
  id: Scalars['Int'];
  strengthWeek?: Maybe<Scalars['Float']>;
  strengthNorm: Scalars['Float'];
};

export type CementKFactor = {
  kFactor: Scalars['Float'];
  cement: Cement;
};

export type CementKFactorInput = {
  kFactor: Scalars['Float'];
  cement: CementInput;
};

export type Charge = {
  amount: Scalars['Float'];
  resources: Array<ChargeResource>;
};

export type ChargeInput = {
  amount: Scalars['Float'];
  resources: Array<ChargeResourceInput>;
};

export type ChargeResource = {
  amount: Scalars['Float'];
};

export type ChargeResourceInput = {
  amount: Scalars['Float'];
};

export type ChlorideClass = {
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  maxChloride: Scalars['Float'];
  id: Scalars['Int'];
};

export type ChlorideClassInput = {
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  maxChloride: Scalars['Float'];
  id: Scalars['Int'];
};

export type ConsistencyClass = {
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  minRequirement: Scalars['Float'];
  maxRequirement?: Maybe<Scalars['Float']>;
  minDeviation?: Maybe<Scalars['Float']>;
  maxDeviation?: Maybe<Scalars['Float']>;
  maxDeviationsAllowed?: Maybe<Scalars['Int']>;
  consistencyTestTypes: Array<ConsistencyTestType>;
  id: Scalars['Int'];
};

export type ConsistencyClassInput = {
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  minRequirement: Scalars['Float'];
  maxRequirement?: Maybe<Scalars['Float']>;
  minDeviation?: Maybe<Scalars['Float']>;
  maxDeviation?: Maybe<Scalars['Float']>;
  maxDeviationsAllowed?: Maybe<Scalars['Int']>;
  consistencyTestTypes: Array<ConsistencyTestTypeInput>;
  id: Scalars['Int'];
};

export type ConsistencyTest = {
  consistencyTestType: ConsistencyTestType;
  values: Array<ConsistencyTestValue>;
};

export type ConsistencyTestInput = {
  consistencyTestType: ConsistencyTestTypeInput;
  values: Array<ConsistencyTestValueInput>;
};

export type ConsistencyTestParameter = {
  unit: Scalars['String'];
  label: Scalars['String'];
  placeholder: Scalars['String'];
};

export type ConsistencyTestParameterInput = {
  unit: Scalars['String'];
  label: Scalars['String'];
  placeholder: Scalars['String'];
};

export type ConsistencyTestType = {
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  parameters: Array<ConsistencyTestParameter>;
  id: Scalars['Int'];
};

export type ConsistencyTestTypeInput = {
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  parameters: Array<ConsistencyTestParameterInput>;
  id: Scalars['Int'];
};

export type ConsistencyTestValue = {
  value?: Maybe<Scalars['Float']>;
  parameter: ConsistencyTestParameter;
};

export type ConsistencyTestValueInput = {
  value?: Maybe<Scalars['Float']>;
  parameter: ConsistencyTestParameterInput;
};

export type CreateFamilyInput = {
  name: Scalars['String'];
  id?: Maybe<Scalars['Int']>;
};

export type CreateReceiptInput = {
  date: Scalars['DateTime'];
  customer?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  truck?: Maybe<Scalars['Float']>;
  amount: Scalars['Float'];
  charges?: Maybe<Array<ChargeInput>>;
  plant: PlantInput;
  id: Scalars['Int'];
  revision: RecipeRevisionInput;
  cubeTest?: Maybe<CubeTestInput>;
};

export type CreateRecipeInput = {
  recipeName: Scalars['String'];
  chlorideClass?: Maybe<ChlorideClassInput>;
  consistencyClass: ConsistencyClassInput;
  environmentClasses?: Maybe<Array<EnvironmentClassInput>>;
  strengthClass?: Maybe<StrengthClassInput>;
  typeOfWork?: Maybe<TypeOfWorkInput>;
  gradingCurve?: Maybe<GradingCurveInput>;
  attest?: Maybe<AttestInput>;
  airPercentage: Scalars['Float'];
  wbf: Scalars['Float'];
  allowRecycleWater?: Maybe<Scalars['Boolean']>;
  remarksInternal?: Maybe<Scalars['String']>;
  remarksExternal?: Maybe<Scalars['String']>;
  codeExternal?: Maybe<Scalars['String']>;
  revision?: Maybe<Scalars['Int']>;
  status: RecipeStatus;
  publishedBy?: Maybe<Scalars['String']>;
  publishedDate?: Maybe<Scalars['DateTime']>;
  archivedBy?: Maybe<Scalars['String']>;
  archivedDate?: Maybe<Scalars['DateTime']>;
  families: Array<CreateFamilyInput>;
  ingredients: Array<IngredientInput>;
};

export type CreateResourceInput = {
  name: Scalars['String'];
  type: ResourceType;
  articleCode?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  supplier?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  density: Scalars['Float'];
  chloridePercentage?: Maybe<Scalars['Float']>;
  alkaliPercentage?: Maybe<Scalars['Float']>;
  plants: Array<PlantInput>;
  absorption?: Maybe<Scalars['Float']>;
  moisture?: Maybe<Scalars['Float']>;
  isSand?: Maybe<Scalars['Boolean']>;
  sieveTest?: Maybe<SieveTestInput>;
  gradingCurve?: Maybe<GradingCurveInput>;
  strengthWeek?: Maybe<Scalars['Float']>;
  strengthNorm?: Maybe<Scalars['Float']>;
  dosingMethod?: Maybe<DosingMethod>;
  mainEffect?: Maybe<ExcipientEffect>;
  secondaryEffect?: Maybe<ExcipientEffect>;
  percentageFine?: Maybe<Scalars['Float']>;
  cementKFactors?: Maybe<Array<CementKFactorInput>>;
  siltPercentage?: Maybe<Scalars['Float']>;
};

export type Cube = {
  hash: Scalars['String'];
  type: CubeType;
  weight?: Maybe<Scalars['Float']>;
  pressureStrength?: Maybe<Scalars['Float']>;
  penetration?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
  testDate: Scalars['DateTime'];
  numberOfDays: Scalars['Int'];
  remarks?: Maybe<Scalars['String']>;
  cancelRemarks?: Maybe<Scalars['String']>;
  status: CubeStatus;
  length?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

export type CubeInput = {
  hash: Scalars['String'];
  type: CubeType;
  weight?: Maybe<Scalars['Float']>;
  pressureStrength?: Maybe<Scalars['Float']>;
  penetration?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
  testDate: Scalars['DateTime'];
  numberOfDays: Scalars['Int'];
  remarks?: Maybe<Scalars['String']>;
  cancelRemarks?: Maybe<Scalars['String']>;
  status: CubeStatus;
  length?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

export enum CubeStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED'
}

export type CubeTest = {
  sampleDate: Scalars['DateTime'];
  weight?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
  airPercentage?: Maybe<Scalars['Float']>;
  trayWeightEmpty?: Maybe<Scalars['Float']>;
  trayWeightWet?: Maybe<Scalars['Float']>;
  trayWeightDry?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  cubes: Array<Cube>;
  consistencyTests: Array<ConsistencyTest>;
  cubeNumber: Scalars['Float'];
};

export type CubeTestInput = {
  sampleDate: Scalars['DateTime'];
  weight?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
  airPercentage?: Maybe<Scalars['Float']>;
  trayWeightEmpty?: Maybe<Scalars['Float']>;
  trayWeightWet?: Maybe<Scalars['Float']>;
  trayWeightDry?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  cubes: Array<CubeInput>;
  consistencyTests: Array<ConsistencyTestInput>;
};

export enum CubeType {
  Pressure = 'PRESSURE',
  Penetration = 'PENETRATION',
  Ripeness = 'RIPENESS'
}


export enum DateType {
  SampleDate = 'SAMPLE_DATE',
  TestDate = 'TEST_DATE',
  ReceiptDate = 'RECEIPT_DATE'
}

export enum DosingMethod {
  Liter = 'liter',
  Amount = 'amount',
  Percent = 'percent'
}

export type EnvironmentClass = {
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  minCement: Scalars['Float'];
  maxWbf: Scalars['Float'];
  requirementMax: Scalars['Float'];
  deviationMax: Scalars['Float'];
  maxDeviationsAllowed: Scalars['Int'];
  minimumAirPercentages?: Maybe<Array<MinimumAirPercentage>>;
  encroachment: EnvironmentClassEncroachment;
  id: Scalars['Int'];
};

export enum EnvironmentClassEncroachment {
  None = 'NONE',
  Carbon = 'CARBON',
  Salt = 'SALT',
  Frost = 'FROST',
  Sea = 'SEA',
  Chemical = 'CHEMICAL',
  Mechanical = 'MECHANICAL'
}

export type EnvironmentClassInput = {
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  minCement: Scalars['Float'];
  maxWbf: Scalars['Float'];
  requirementMax: Scalars['Float'];
  deviationMax: Scalars['Float'];
  maxDeviationsAllowed: Scalars['Int'];
  minimumAirPercentages?: Maybe<Array<MinimumAirPercentageInput>>;
  encroachment: EnvironmentClassEncroachment;
  id: Scalars['Int'];
};

export type Excipient = {
  name: Scalars['String'];
  type: ResourceType;
  articleCode?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  supplier?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  density: Scalars['Float'];
  chloridePercentage?: Maybe<Scalars['Float']>;
  alkaliPercentage?: Maybe<Scalars['Float']>;
  plants: Array<Plant>;
  active: Boolean;
  cvalue?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
  dosingMethod: DosingMethod;
  mainEffect: ExcipientEffect;
  secondaryEffect?: Maybe<ExcipientEffect>;
  mainExcipientEffectId: Scalars['Int'];
  secondaryExcipientEffectId?: Scalars['Int'];
  absorption?: Maybe<Scalars['Float']>;
  moisture?: Maybe<Scalars['Float']>;
};

export enum ExcipientEffect {
  Accelerator = 1,
  Retarder = 2,
  Plastifier = 3,
  SuperPlastifier = 4,
  AirBubbleMaker = 5
}

export type excipientEffect = {
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type Extra = {
  name: Scalars['String'];
  type: ResourceType;
  articleCode?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  supplier?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  density: Scalars['Float'];
  chloridePercentage?: Maybe<Scalars['Float']>;
  alkaliPercentage?: Maybe<Scalars['Float']>;
  plants: Array<Plant>;
  active: Boolean;
  cvalue?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
  percentageFine: Scalars['Float'];
  absorption?: Maybe<Scalars['Float']>;
  moisture?: Maybe<Scalars['Float']>;
};

export type Family = {
  name: Scalars['String'];
  id: Scalars['Int'];
};

export type Filler = {
  name: Scalars['String'];
  type: ResourceType;
  articleCode?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  supplier?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  density: Scalars['Float'];
  chloridePercentage?: Maybe<Scalars['Float']>;
  alkaliPercentage?: Maybe<Scalars['Float']>;
  plants: Array<Plant>;
  active: Boolean;
  cvalue?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
  cementKFactors: Array<CementKFactor>;
};

export type GradingCurve = {
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type: SieveSetType;
  sieveSteps: Array<GradingCurveStep>;
  id: Scalars['Int'];
  dMax: Scalars['Float'];
};

export type GradingCurveInput = {
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type: SieveSetType;
  sieveSteps: Array<GradingCurveStepInput>;
  id: Scalars['Int'];
};

export type GradingCurveStep = {
  min: Scalars['Int'];
  max: Scalars['Int'];
  maxAlt?: Maybe<Scalars['Int']>;
  sieveSize: SieveSize;
};

export type GradingCurveStepInput = {
  min: Scalars['Int'];
  max: Scalars['Int'];
  maxAlt?: Maybe<Scalars['Int']>;
  sieveSize: SieveSizeInput;
};

export type Ingredient = {
  amount: Scalars['Float'];
  percentage?: Maybe<Scalars['Float']>;
  moisture?: Maybe<Scalars['Float']>;
  absorption?: Maybe<Scalars['Float']>;
  kFactor?: Maybe<Scalars['Float']>;
  attestPercentage?: Maybe<Scalars['Float']>;
  resource: ResourceUnion;
};

export type IngredientInput = {
  amount: Scalars['Float'];
  percentage?: Maybe<Scalars['Float']>;
  moisture?: Maybe<Scalars['Float']>;
  absorption?: Maybe<Scalars['Float']>;
  kFactor?: Maybe<Scalars['Float']>;
  attestPercentage?: Maybe<Scalars['Float']>;
  resource: ResourceInput;
};

export type LoginResponse = {
  access_token: Scalars['String'];
  scope?: Maybe<Scalars['String']>;
  expires_in: Scalars['String'];
  token_type: Scalars['String'];
};

export type MinimumAirPercentage = {
  largestGrain: Scalars['Float'];
  airPercentage: Scalars['Float'];
};

export type MinimumAirPercentageInput = {
  largestGrain: Scalars['Float'];
  airPercentage: Scalars['Float'];
};

export type Mutation = {
  createChlorideClass: ChlorideClass;
  updateChlorideClass: ChlorideClass;
  deleteChlorideClass: UpdateResult;
  createRecipe: Recipe;
  updateRecipe: Recipe;
  archiveRecipe: UpdateResult;
  publishRecipe: UpdateResult;
  unPublishRecipe: UpdateResult;
  login: LoginResponse;
  resetPassword?: Maybe<Scalars['String']>;
  createConsistencyClass: ConsistencyClass;
  updateConsistencyClass: ConsistencyClass;
  deleteConsistencyClass: UpdateResult;
  createConsistencyTestType: ConsistencyTestType;
  updateConsistencyTestType: ConsistencyTestType;
  deleteConsistencyTestType: UpdateResult;
  createEnvironmentClass: EnvironmentClass;
  updateEnvironmentClass: EnvironmentClass;
  deleteEnvironmentClass: UpdateResult;
  createStrengthClass: StrengthClass;
  updateStrengthClass: StrengthClass;
  deleteStrengthClass: UpdateResult;
  createTypeOfWork: TypeOfWork;
  updateTypeOfWork: TypeOfWork;
  deleteTypeOfWork: UpdateResult;
  createReceipt: Receipt;
  updateReceipt: Receipt;
  deleteCubeTest: Receipt;
  deleteReceipt: UpdateResult;
  createResource: ResourceUnion;
  updateResource: ResourceUnion;
  deleteResource: UpdateResult;
  createFamily: Family;
  updateFamily: Family;
  deleteFamily: UpdateResult;
  createGradingCurve: GradingCurve;
  updateGradingCurve: GradingCurve;
  deleteGradingCurve: UpdateResult;
  createPlant: Plant;
  updatePlant: Plant;
  deletePlant: UpdateResult;
  createSieveSet: SieveSet;
  updateSieveSet: SieveSet;
  deleteSieveSet: UpdateResult;
  createSieveSize: SieveSize;
  updateSieveSize: SieveSize;
  deleteSieveSize: UpdateResult;
  createAttest: Attest;
  updateAttest: Attest;
  deleteAttest: UpdateResult;
};


export type MutationCreateChlorideClassArgs = {
  chlorideClass: BaseChlorideClass;
};


export type MutationUpdateChlorideClassArgs = {
  chlorideClassId: Scalars['Int'];
  chlorideClass: BaseChlorideClass;
};


export type MutationDeleteChlorideClassArgs = {
  chlorideClassId: Scalars['Int'];
};


export type MutationCreateRecipeArgs = {
  recipe: CreateRecipeInput;
};


export type MutationUpdateRecipeArgs = {
  recipeId: Scalars['Int'];
  recipe: CreateRecipeInput;
};


export type MutationArchiveRecipeArgs = {
  recipeId: Scalars['Int'];
};


export type MutationPublishRecipeArgs = {
  recipeId: Scalars['Int'];
};


export type MutationUnPublishRecipeArgs = {
  recipeId: Scalars['Int'];
};


export type MutationLoginArgs = {
  scope?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  username: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  email: Scalars['String'];
};


export type MutationCreateConsistencyClassArgs = {
  consistencyClass: BaseConsistencyClass;
};


export type MutationUpdateConsistencyClassArgs = {
  consistencyClassId: Scalars['Int'];
  consistencyClass: BaseConsistencyClass;
};


export type MutationDeleteConsistencyClassArgs = {
  consistencyClassId: Scalars['Int'];
};


export type MutationCreateConsistencyTestTypeArgs = {
  consistencyTestType: BaseConsistencyTestType;
};


export type MutationUpdateConsistencyTestTypeArgs = {
  consistencyTestTypeId: Scalars['Int'];
  consistencyTestType: BaseConsistencyTestType;
};


export type MutationDeleteConsistencyTestTypeArgs = {
  consistencyTestTypeId: Scalars['Int'];
};


export type MutationCreateEnvironmentClassArgs = {
  environmentClass: BaseEnvironmentClass;
};


export type MutationUpdateEnvironmentClassArgs = {
  environmentClassId: Scalars['Int'];
  environmentClass: BaseEnvironmentClass;
};


export type MutationDeleteEnvironmentClassArgs = {
  environmentClassId: Scalars['Int'];
};


export type MutationCreateStrengthClassArgs = {
  strengthClass: BaseStrengthClass;
};


export type MutationUpdateStrengthClassArgs = {
  strengthClassId: Scalars['Int'];
  strengthClass: BaseStrengthClass;
};


export type MutationDeleteStrengthClassArgs = {
  strengthClassId: Scalars['Int'];
};


export type MutationCreateTypeOfWorkArgs = {
  typeOfWork: BaseTypeOfWork;
};


export type MutationUpdateTypeOfWorkArgs = {
  typeOfWorkId: Scalars['Int'];
  typeOfWork: BaseTypeOfWork;
};


export type MutationDeleteTypeOfWorkArgs = {
  typeOfWorkId: Scalars['Int'];
};


export type MutationCreateReceiptArgs = {
  receipt: CreateReceiptInput;
};


export type MutationUpdateReceiptArgs = {
  receiptId: Scalars['Int'];
  receipt: UpdateReceiptInput;
};


export type MutationDeleteCubeTestArgs = {
  receiptId: Scalars['Int'];
};


export type MutationDeleteReceiptArgs = {
  receiptId: Scalars['Int'];
};


export type MutationCreateResourceArgs = {
  resource: CreateResourceInput;
};


export type MutationUpdateResourceArgs = {
  resourceId: Scalars['Int'];
  resource: CreateResourceInput;
};


export type MutationDeleteResourceArgs = {
  resourceId: Scalars['Int'];
};


export type MutationCreateFamilyArgs = {
  family: BaseFamily;
};


export type MutationUpdateFamilyArgs = {
  familyId: Scalars['Int'];
  family: BaseFamily;
};


export type MutationDeleteFamilyArgs = {
  familyId: Scalars['Int'];
};


export type MutationCreateGradingCurveArgs = {
  gradingCurve: BaseGradingCurve;
};


export type MutationUpdateGradingCurveArgs = {
  gradingCurveId: Scalars['Int'];
  gradingCurve: BaseGradingCurve;
};


export type MutationDeleteGradingCurveArgs = {
  gradingCurveId: Scalars['Int'];
};


export type MutationCreatePlantArgs = {
  plant: BasePlant;
};


export type MutationUpdatePlantArgs = {
  plantId: Scalars['Int'];
  plant: BasePlant;
};


export type MutationDeletePlantArgs = {
  plantId: Scalars['Int'];
};


export type MutationCreateSieveSetArgs = {
  sieveSet: BaseSieveSet;
};


export type MutationUpdateSieveSetArgs = {
  sieveSetId: Scalars['Int'];
  sieveSet: BaseSieveSet;
};


export type MutationDeleteSieveSetArgs = {
  sieveSetId: Scalars['Int'];
};


export type MutationCreateSieveSizeArgs = {
  sieveSize: BaseSieveSize;
};


export type MutationUpdateSieveSizeArgs = {
  sieveSizeId: Scalars['Int'];
  sieveSize: BaseSieveSize;
};


export type MutationDeleteSieveSizeArgs = {
  sieveSizeId: Scalars['Int'];
};


export type MutationCreateAttestArgs = {
  attest: BaseAttest;
};


export type MutationUpdateAttestArgs = {
  attestId: Scalars['Int'];
  attest: BaseAttest;
};


export type MutationDeleteAttestArgs = {
  attestId: Scalars['Int'];
};

export type Plant = {
  name: Scalars['String'];
  id: Scalars['Int'];
};

export type PlantInput = {
  name: Scalars['String'];
  id: Scalars['Int'];
};

export type Query = {
  chlorideClass: ChlorideClass;
  chlorideClasses: Array<ChlorideClass>;
  recipes: Array<Recipe>;
  recipesWithResource: Array<Recipe>;
  recipe: Recipe;
  user: User;
  consistencyClass: ConsistencyClass;
  consistencyClasses: Array<ConsistencyClass>;
  consistencyTestType: ConsistencyTestType;
  consistencyTestTypes: Array<ConsistencyTestType>;
  environmentClass: EnvironmentClass;
  environmentClasses: Array<EnvironmentClass>;
  strengthClass: StrengthClass;
  strengthClasses: Array<StrengthClass>;
  typeOfWork: TypeOfWork;
  typeOfWorks: Array<TypeOfWork>;
  receipts: Array<Receipt>;
  receipt: Receipt;
  receiptWithCubeNumber: Receipt;
  resources: Array<ResourceUnion>;
  resource: ResourceUnion;
  family: Family;
  families: Array<Family>;
  gradingCurve: GradingCurve;
  gradingCurves: Array<GradingCurve>;
  plant: Plant;
  plants: Array<Plant>;
  sieveSet: SieveSet;
  sieveSets: Array<SieveSet>;
  sieveSize: SieveSize;
  sieveSizes: Array<SieveSize>;
  attest: Attest;
  attests: Array<Attest>;
};


export type QueryChlorideClassArgs = {
  id: Scalars['Int'];
};


export type QueryRecipesWithResourceArgs = {
  resourceId: Scalars['Int'];
};


export type QueryRecipeArgs = {
  recipeId: Scalars['Int'];
};


export type QueryUserArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryConsistencyClassArgs = {
  id: Scalars['Int'];
};


export type QueryConsistencyTestTypeArgs = {
  id: Scalars['Int'];
};


export type QueryEnvironmentClassArgs = {
  id: Scalars['Int'];
};


export type QueryStrengthClassArgs = {
  id: Scalars['Int'];
};


export type QueryTypeOfWorkArgs = {
  id: Scalars['Int'];
};


export type QueryReceiptsArgs = {
  endDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  dateType?: Maybe<DateType>;
  plantId: Scalars['Int'];
};


export type QueryReceiptArgs = {
  receiptId: Scalars['Int'];
};


export type QueryReceiptWithCubeNumberArgs = {
  cubeNumber: Scalars['Int'];
  plantId: Scalars['Int'];
};


export type QueryResourceArgs = {
  resourceId: Scalars['Int'];
};


export type QueryFamilyArgs = {
  id: Scalars['Int'];
};


export type QueryGradingCurveArgs = {
  id: Scalars['Int'];
};


export type QueryPlantArgs = {
  id: Scalars['Int'];
};


export type QuerySieveSetArgs = {
  id: Scalars['Int'];
};


export type QuerySieveSizeArgs = {
  id: Scalars['Int'];
};


export type QueryAttestArgs = {
  id: Scalars['Int'];
};

export type Receipt = {
  date: Scalars['DateTime'];
  customer?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  truck?: Maybe<Scalars['Float']>;
  amount: Scalars['Float'];
  charges?: Maybe<Array<Charge>>;
  plant: Plant;
  id: Scalars['Int'];
  revision: RecipeRevision;
  cubeTest?: Maybe<CubeTest>;
};

export type Recipe = {
  recipeName: Scalars['String'];
  chlorideClass?: Maybe<ChlorideClass>;
  consistencyClass: ConsistencyClass;
  environmentClasses?: Maybe<Array<EnvironmentClass>>;
  strengthClass?: Maybe<StrengthClass>;
  typeOfWork?: Maybe<TypeOfWork>;
  gradingCurve?: Maybe<GradingCurve>;
  attest?: Maybe<Attest>;
  airPercentage: Scalars['Float'];
  wbf: Scalars['Float'];
  allowRecycleWater?: Maybe<Scalars['Boolean']>;
  remarksInternal?: Maybe<Scalars['String']>;
  remarksExternal?: Maybe<Scalars['String']>;
  codeExternal?: Maybe<Scalars['String']>;
  revision?: Maybe<Scalars['Int']>;
  status: RecipeStatus;
  publishedBy?: Maybe<Scalars['String']>;
  publishedDate?: Maybe<Scalars['DateTime']>;
  archivedBy?: Maybe<Scalars['String']>;
  archivedDate?: Maybe<Scalars['DateTime']>;
  families?: Maybe<Array<Family>>;
  id: Scalars['Int'];
  revisions: Array<RecipeRevision>;
  ingredients: Array<Ingredient>;
  binderTotal: Scalars['Float'];
  percentageFine: Scalars['Float'];
  density: Scalars['Float'];
  absorption: Scalars['Float'];
};

export type RecipeRevision = {
  id: Scalars['Int'];
  date: Scalars['DateTime'];
  author: Scalars['String'];
  recipe: Recipe;
};

export type RecipeRevisionInput = {
  id: Scalars['Int'];
};

export enum RecipeStatus {
  Error = 'ERROR',
  Published = 'PUBLISHED',
  Changed = 'CHANGED',
  Archived = 'ARCHIVED'
}

export type ResourceInput = {
  name: Scalars['String'];
  type: ResourceType;
  articleCode?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  supplier?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  density: Scalars['Float'];
  chloridePercentage?: Maybe<Scalars['Float']>;
  alkaliPercentage?: Maybe<Scalars['Float']>;
  plants: Array<PlantInput>;
  absorption?: Maybe<Scalars['Float']>;
  moisture?: Maybe<Scalars['Float']>;
  isSand?: Maybe<Scalars['Boolean']>;
  sieveTest?: Maybe<SieveTestInput>;
  gradingCurve?: Maybe<GradingCurveInput>;
  strengthWeek?: Maybe<Scalars['Float']>;
  strengthNorm?: Maybe<Scalars['Float']>;
  dosingMethod?: Maybe<DosingMethod>;
  mainEffect?: Maybe<ExcipientEffect>;
  secondaryEffect?: Maybe<ExcipientEffect>;
  mainExcipientEffectId: Scalars['Int'];
  secondaryExcipientEffectId?: Scalars['Int'];
  percentageFine?: Maybe<Scalars['Float']>;
  cementKFactors?: Maybe<Array<CementKFactorInput>>;
  siltPercentage?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
};

export enum ResourceType {
  Addition = 'addition',
  Cement = 'cement',
  Excipient = 'excipient',
  Extra = 'extra',
  Filler = 'filler',
  Water = 'water'
}

export type ResourceUnion = Addition | Cement | Excipient | Extra | Filler | Water;

export type SieveSet = {
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type: SieveSetType;
  sieveSteps: Array<SieveStep>;
  id: Scalars['Int'];
};

export type SieveSetInput = {
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type: SieveSetType;
  sieveSteps: Array<SieveStepInput>;
  id: Scalars['Int'];
};

export enum SieveSetType {
  Sand = 'SAND',
  Gravel = 'GRAVEL',
  Mixture = 'MIXTURE'
}

export type SieveSize = {
  code?: Maybe<Scalars['String']>;
  size: Scalars['Float'];
  id: Scalars['Int'];
};

export type SieveSizeInput = {
  code?: Maybe<Scalars['String']>;
  size: Scalars['Float'];
  id: Scalars['Int'];
};

export type SieveStep = {
  sieveSize: SieveSize;
};

export type SieveStepInput = {
  sieveSize: SieveSizeInput;
};

export type SieveTest = {
  startingWeight: Scalars['Float'];
  sieveSet?: Maybe<SieveSet>;
  sieveSteps: Array<SieveTestStep>;
  finenessModulus: Scalars['Float'];
};

export type SieveTestInput = {
  startingWeight: Scalars['Float'];
  sieveSet?: Maybe<SieveSetInput>;
  sieveSteps: Array<SieveTestStepInput>;
};

export type SieveTestStep = {
  sieveSize: SieveSize;
  restWeight: Scalars['Float'];
  fallThrough?: number;
};

export type SieveTestStepInput = {
  sieveSize: SieveSizeInput;
  restWeight: Scalars['Float'];
};

export type StrengthClass = {
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  cilinderPressureStrength: Scalars['Float'];
  cubePressureStrength: Scalars['Float'];
  bendTensileStrength: Scalars['Float'];
  samplePerVolume: Scalars['Int'];
  sampleMinimum: Scalars['Int'];
  sampleMaximum: Scalars['Int'];
  id: Scalars['Int'];
};

export type StrengthClassInput = {
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  cilinderPressureStrength: Scalars['Float'];
  cubePressureStrength: Scalars['Float'];
  bendTensileStrength: Scalars['Float'];
  samplePerVolume: Scalars['Int'];
  sampleMinimum: Scalars['Int'];
  sampleMaximum: Scalars['Int'];
  id: Scalars['Int'];
};

export type Subscription = {
  newRecipe?: Maybe<Recipe>;
  publishedRecipe?: Maybe<Scalars['Float']>;
  unpublishedRecipe?: Maybe<Scalars['Float']>;
  newResource?: Maybe<ResourceUnion>;
  updatedResource?: Maybe<ResourceUnion>;
  deletedResource?: Maybe<Scalars['Float']>;
};

export type TypeOfWork = {
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  maxChloride: Scalars['Float'];
  minPercentageFine?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
};

export type TypeOfWorkInput = {
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  maxChloride: Scalars['Float'];
  minPercentageFine?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
};

export type UpdateReceiptInput = {
  date: Scalars['DateTime'];
  customer?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  truck?: Maybe<Scalars['Float']>;
  amount: Scalars['Float'];
  charges?: Maybe<Array<ChargeInput>>;
  plant?: Maybe<PlantInput>;
  revision?: Maybe<RecipeRevisionInput>;
  cubeTest: CubeTestInput;
};

export type UpdateResult = {
  success: Scalars['Boolean'];
};

export type User = {
  email: Scalars['String'];
  email_verified: Scalars['Boolean'];
  name: Scalars['String'];
  nickname: Scalars['String'];
  username: Scalars['String'];
  picture: Scalars['String'];
  user_id: Scalars['String'];
  last_ip: Scalars['String'];
  logins_count: Scalars['Int'];
  last_login: Scalars['DateTime'];
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
};

export type Water = {
  name: Scalars['String'];
  type: ResourceType;
  articleCode?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  supplier?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  density: Scalars['Float'];
  chloridePercentage?: Maybe<Scalars['Float']>;
  alkaliPercentage?: Maybe<Scalars['Float']>;
  plants: Array<Plant>;
  active: Boolean;
  cvalue?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
  siltPercentage?: Maybe<Scalars['Float']>;
};
