import axiosHttpClient from "../axiosHttpClient";

export function post(data: any) {
	return axiosHttpClient.post('/resource', data);
}

export async function getAll(active: boolean=null) {
	let param='';
	if(active!==null) {
		param=active ? 'true' : 'false';
		param='/'+param;
	}
	return axiosHttpClient.get('/resources'+param);
}

export async function getOne(id: number, relations: string=null) {
	let url='/resource/one/'+id.toString();
	if(relations) url+='/'+relations;
	return axiosHttpClient.get(url);
}
