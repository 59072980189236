import React, { useCallback, useState, useEffect, Fragment } from 'react';
import { Dialog, DialogTitle, DialogActions, Button, Slide, LinearProgress, Icon, Typography, List, ListItem, ListItemText, ListItemSecondaryAction, DialogContent, FormControlLabel, Checkbox } from '@material-ui/core';
import { ConfirmDialogProps } from '../ConfirmDialog';
import { Recipe } from '../../types';
import { getErrors, getRecipeConstraints } from '../../computed/recipeComputations';
import { format } from 'react-numberinput-formatter';
import { TransitionProps } from '@material-ui/core/transitions/transition';

const items = {
  maxWBF: {
    label: 'WBF',
    parsedValue: (value: number) => <Fragment>{format(value, { maximumFractionDigits: 3 })}</Fragment>,
    parsedConstraint: (min?: number, max?: number) => max ? <Fragment>Maximaal {format(max)}</Fragment> : null
  },
  binderTotal: {
    label: 'Totaal bindmiddel',
    parsedValue: (value: number) => <Fragment>{format(value, { maximumFractionDigits: 0 })} kg</Fragment>,
    parsedConstraint: (min?: number, max?: number) => min ? <Fragment>Minimaal {min} kg</Fragment> : null
  },
  percentageFine: {
    label: 'Gehalte fijn',
    parsedValue: (value: number) => <Fragment>{format(value, { maximumFractionDigits: 1 })} L</Fragment>,
    parsedConstraint: (min?: number, max?: number) => min ? <Fragment>Minimaal {min} L</Fragment> : null
  },
  predictedStrength: {
    label: 'Berekende sterkte',
    parsedValue: (value: number) => <Fragment>{format(value, { maximumFractionDigits: 1 })} N/mm<sup>2</sup></Fragment>,
    parsedConstraint: (min?: number, max?: number) => min ? <Fragment>Minimaal {min} N/mm<sup>2</sup></Fragment> : null
  },
  chloridePercentage: {
    label: 'Chloride gehalte',
    parsedValue: (value: number) => <Fragment>{format(value, { maximumFractionDigits: 3 })}%</Fragment>,
    parsedConstraint: (min?: number, max?: number) => max ? <Fragment>Maximaal {format(max)}%</Fragment> : null
  },
  alkaliPercentage: {
    label: 'Alkali gehalte',
    parsedValue: (value: number) => <Fragment>{format(value, { maximumFractionDigits: 3 })}%</Fragment>,
    parsedConstraint: (min?: number, max?: number) => max ? <Fragment>Maximaal {format(max)}%</Fragment> : null
  },
  airPercentage: {
    label: 'Luchtpercentage',
    parsedValue: (value: number) => <Fragment>{format(value)}%</Fragment>,
    parsedConstraint: (min?: number, max?: number) => <Fragment>{min ? 'Minimaal ' + min : (max ? 'Maximaal ' + max : null)}%</Fragment>
  }
}

const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props:any, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export type ConfirmErrorsDialogProps = Omit<ConfirmDialogProps, 'title' | 'content' | 'confirmText' | 'cancelText' | 'onConfirm'> & {
  onConfirm?: (ignoreErrors: boolean) => void,
  recipe?: Recipe
}

const ConfirmErrorsDialog: React.FC<ConfirmErrorsDialogProps> = ({ open, onCancel, onConfirm, recipe }) => {
  const [loading, setLoading] = useState(false)
  const [ignore, setIgnore] = useState(false)

  const constraints: any = recipe ? getRecipeConstraints(recipe) : {}
  const errors = (recipe ? getErrors(recipe) : []) as Array<keyof typeof items>

  useEffect(() => {
    setLoading(false);
  }, [open]);

  const handleConfirm = useCallback(() => {
    setLoading(true);
    onConfirm && onConfirm(ignore);
  }, [onConfirm, ignore]);

  return (
    <Dialog
      open={open}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      maxWidth="xs"
      TransitionComponent={Transition}
      onEntered={(ref: HTMLElement) => ref.removeAttribute('tabindex')}
    >
      <DialogTitle disableTypography={true}><Typography variant="h6" style={{ display: 'flex', alignItems: 'center' }}><Icon color="error">warning</Icon>&nbsp;&nbsp;Het recept heeft fouten</Typography></DialogTitle>
      <List dense={true}>
        {errors.map(k => {
          const { value, min, max } = constraints[k]
          const { label, parsedValue, parsedConstraint } = items[k]
          return (<ListItem key={k}>
            <ListItemText primary={label} secondary={parsedConstraint(min, max)} />
            <ListItemSecondaryAction><Typography>{parsedValue(value)}</Typography></ListItemSecondaryAction>
          </ListItem>)
        })}
      </List>
      <DialogContent>
        <Typography variant="caption" color="error">Het recept wordt opgeslagen en getoond met een waarschuwing. Recepten met fouten kunnen <strong>niet</strong> gepubliceerd worden. Toch publiceren? Geef dan hieronder aan dat u de fouten wilt negeren.</Typography>
        <FormControlLabel
          style={{ marginTop: 16 }}
          control={<Checkbox checked={ignore} onChange={() => setIgnore(!ignore)} />}
          label={<Typography variant="body2">Negeer deze fouten en sla op zonder fouten te vermelden</Typography>}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Annuleren</Button>
        <div style={{ display: 'flex' }}>
          <div style={{ position: 'relative' }}>
            <Button onClick={handleConfirm} disabled={loading} color="secondary">Ok</Button>
            {loading && <LinearProgress variant="indeterminate" color="secondary" style={{ background: 'none', position: 'absolute', bottom: 0, left: 0, width: '100%' }} />}
          </div>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmErrorsDialog;
