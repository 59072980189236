import React from 'react';
import { Recipe, ResourceType, Addition, Ingredient } from '../../types';
import { getMixtureCummulativeRestPercentages, getCummulativeRestPercentages, getFinenessModulus, getLargestGrain } from '../../computed/recipeComputations';
import { Card, Table, TableHead, TableCell, TableRow, TableBody, TableFooter, Link, Typography } from '@material-ui/core';
import { CardProps } from '@material-ui/core/Card';
import GradingCurveChart from './GradingCurveChart';
import NumberFormat from 'react-number-format';
import { green } from '@material-ui/core/colors';
import useAuthorized from '../../useAuthorized';

const format = {
  decimalSeparator: ',',
  thousandSeparator: '.',
  decimalScale: 1,
  displayType: 'text' as 'text'
}

const GradingCurveOverview: React.FC<{ recipe: Recipe, goto: (step: number) => void } & CardProps> = ({ recipe, goto, ...cardProps }) => {
  const { ingredients, gradingCurve } = recipe
  const additions = ingredients.filter(r => r.resource.type === ResourceType.Addition).sort((a, b) => (a.resource as Addition).isSand ? -1 : 0) as Array<Ingredient & { resource: Addition }>;
  const additionPercentages = additions.map(a => ({ resource: a.resource, amount: a.amount, restPercentages: a.resource.sieveTest ? getCummulativeRestPercentages(a.resource.sieveTest) : [] }))
  const mixturePercentages = getMixtureCummulativeRestPercentages(additionPercentages);
  const largestGrain = getLargestGrain(additions as any);
  const los = !Boolean(recipe.strengthClass);
  const canEdit = useAuthorized(['update:recipes'])

  return (
    <Card {...cardProps}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell rowSpan={2}>Zeefmaat</TableCell>
            <TableCell colSpan={additions.length} align="center">Toeslagen</TableCell>
            <TableCell colSpan={2} align="center">Mengsel</TableCell>
            <TableCell colSpan={2} align="center" style={{ whiteSpace: 'nowrap' }}>{gradingCurve ? gradingCurve.description : 'Gradering'} {canEdit && <Typography variant="subtitle2"><Link onClick={() => goto(los ? 5 : 9)} className="no-print">Aanpassen</Link></Typography>}</TableCell>
          </TableRow>
          <TableRow>
            {additionPercentages.map((a, k) => <TableCell key={k} align="right">{a.resource.name}</TableCell>)}
            <TableCell align="right">Zeefrest</TableCell>
            <TableCell align="right">Doorval</TableCell>
            <TableCell align="right">Min.</TableCell>
            <TableCell align="right">Max.</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {mixturePercentages.sort((a, b) => b.sieveSize.size - a.sieveSize.size).map((r, k) => {
            const grading = gradingCurve && gradingCurve.sieveSteps && gradingCurve.sieveSteps.find(st => st.sieveSize.size === r.sieveSize.size);
            return (<TableRow key={k} {...largestGrain === r.sieveSize.size && { style: { background: green[100] } }}>
              <TableCell>{r.sieveSize.code}</TableCell>
              {additionPercentages.map((a, n) => {
                const sieveRest = a.restPercentages && a.restPercentages.find(p => p.sieveSize.size === r.sieveSize.size);
                // @ts-ignore
                return sieveRest && sieveRest.percentage ? <TableCell key={n} align="right" style={{color: sieveRest.inBetweenPercentage!==undefined ? '#999999' : 'inherit'}}><NumberFormat value={Math.round(sieveRest.percentage * 10) / 10} {...format} />%</TableCell> : <TableCell key={n} />
              })}
              <TableCell align="right"><NumberFormat value={Math.round(r.percentage * 10) / 10} {...format} />%</TableCell>
              <TableCell align="right"><NumberFormat value={Math.round((100 - r.percentage) * 10) / 10} {...format} />%</TableCell>
              {grading && grading.min ? <TableCell align="right">{100 - grading.min}%</TableCell> : <TableCell />}
              {grading && grading.max ? <TableCell align="right">{100 - grading.max}%</TableCell> : <TableCell />}
            </TableRow>)
          })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell>Fijnheidsmodulus</TableCell>
            {additionPercentages.map(({ restPercentages }, n) => restPercentages ? <TableCell key={n} align="right"><NumberFormat value={getFinenessModulus(restPercentages)} {...format} decimalScale={2} /></TableCell> : <TableCell key={n} />)}
            <TableCell align="right"><NumberFormat value={getFinenessModulus(mixturePercentages)} {...format} decimalScale={2} /></TableCell>
            <TableCell colSpan={3} />
          </TableRow>
        </TableFooter>
      </Table>
      <GradingCurveChart restPercentages={mixturePercentages} gradingCurve={gradingCurve || undefined} />
    </Card>
  )
}

export default GradingCurveOverview;
