import React, {useCallback, useContext, useEffect, useState} from 'react'
import {Route, Switch, useHistory, useLocation, useParams} from 'react-router-dom'
import {FormControlLabel, FormGroup, Snackbar, Switch as UISwitch} from '@material-ui/core'
import {Recipe, RecipeRevision, RecipeStatus} from '../types'
import CustomSnackbarContent from '../components/CustomSnackbarContent'
import RecipeDialogContent from '../components/recipes/RecipeDialogContent'
import PageWithBreadcrumbs, {BreadcrumbsContext} from '../views/PageWithBreadcrumbs'
import {getErrors, updateRecipe} from '../computed/recipeComputations'
import RecipesList from '../components/recipes/RecipesList'
import Loading from '../components/Loading'
import ConfirmDialog, {ConfirmDialogProps} from '../components/ConfirmDialog'
import Revisions from '../components/recipes/revisions/Revisions'
import {sanitizeRecipe} from '../sanitize'
import {post, getOne, getAll as getRecipes, publish, publishBatch, unPublish, unPublishBatch, archiveBatch} from '../HTTPClients/RecipeApp/recipes/recipes'
import {getAll as getResources} from '../HTTPClients/RecipeApp/resources/resources'
import {getUser} from "../HTTPClients/PlanningApp/users";

declare var JsonUrl: any
const codec = JsonUrl('lzma')

const cachedUsers: any = {}

const AddRecipeContent: React.FC<{ onSave: (recipe: Recipe) => void }> = ({ onSave }) => {
  const { type, step } = useParams<{ type: string, step: string }>()
  const { createBreadcrumbs } = useContext(BreadcrumbsContext)
  const { search } = useLocation()
  useEffect(() => createBreadcrumbs([
    { title: 'Recepten', link: '/recipe' },
    { title: type === 'los' ? 'Nieuwe levering op samenstelling' : 'Nieuwe levering op gebruikseisen', link: type === 'los' ? '/recipe/add/los' + search : '/recipe/add/loge' + search },
    ...step ? [{ title: type === 'los' ? 'Overzicht levering op samenstelling' : 'Overzicht levering op gebruikseisen', link: type === 'los' ? '/recipe/add/los/overview' + search : '/recipe/add/loge/overview' + search }] : []
  ]), [createBreadcrumbs, step, search, type])
  return <RecipeDialogContent onSave={onSave} type={type as 'los' | 'loge'} step={step ? 'overview' : 'edit'} />
}

const EditRecipe: React.FC<{ recipe: Recipe, onSave: (recipe: Recipe) => void }> = ({ onSave, ...props }) => {
  const { step } = useParams<{ step: string }>()
  const { state } = useLocation()
  const [loading, setLoading] = useState(false)
  const [recipe, setRecipe] = useState(props.recipe)

  useEffect(() => {
    setRecipe(props.recipe);
  }, [props.recipe])
  useEffect(() => {
    // @ts-ignore
    if (state && state.reset as number) {
      setLoading(true)
      // @ts-ignore
      const rev = (props.recipe.revisions ? props.recipe.revisions : []).find(r => r.id === state.reset)
      if (rev) {
        /* REC-30 Niet actieve grondstoffen worden verwijderd uit het recept */
        getResources().then(
            function(response) {
              const newRecipe = updateRecipe(rev.recipe, response.data.data.filter(r => rev.recipe.ingredients.map(r => r.resource.id).indexOf(r.id) >= 0))
              setRecipe(r => ({ ...r, ...newRecipe }))
              setLoading(false)
            }
        )
      }
    }
  }, [state, props.recipe.revisions])
  const { createBreadcrumbs } = useContext(BreadcrumbsContext)
  useEffect(() => createBreadcrumbs([
    { title: 'Recepten', link: '/recipe' },
    { title: 'Recept bewerken', link: '/recipe/' + recipe.id },
    ...step ? [{ title: 'Recept overzicht', link: '/recipe/' + recipe.id + '/overview' }] : []
  ]), [createBreadcrumbs, step, recipe])

  return loading ? <Loading /> : <RecipeDialogContent onSave={onSave} recipe={recipe} type={recipe.strengthClass ? 'loge' : 'los'} step={step ? 'overview' : 'edit'} />
}

export type EnhancedRecipe = Recipe & { revisions: Array<RecipeRevision & { user: { name: string, picture: string } }> }

const QueryRecipe: React.FC<{ children: React.FC<EnhancedRecipe> }> = ({ children }) => {
  const { recipeId } = useParams() as { recipeId?: string }
  const [recipe, setRecipe] = useState(undefined as EnhancedRecipe | undefined)
  const [loading, setLoading] = useState(true)

  // TODO
  if(recipe===undefined) {
    getOne(Number(recipeId)).then(
        async function(response) {
          let recipe=response.data.data;
          const promises = (recipe.revisions ? recipe.revisions : []).map(async ({ author, ...rev }) => {
            if (!cachedUsers[author]) {
              try {
                // Get and show author user
                let authorId=parseInt(author);
                if(isNaN(authorId))
                    // Old Auth0 User; Is not stored on the Recipe App Server
                  cachedUsers[author]={ name: 'Auteur niet gevonden', picture: '' }
                else {
                  let response=await getUser(authorId);
                  cachedUsers[author]={ name: response.data.data.username, picture: ''}
                }
              } catch (e) {
                cachedUsers[author] = { name: 'Auteur niet gevonden', picture: '' }
              }
            }
            return { ...rev, author, user: cachedUsers[author] }
          })
          const revisions = await Promise.all(promises)
          setRecipe({ ...recipe, revisions })
          setLoading(false)
        }
    )
  }
  return !loading && recipe ? children(recipe) : loading ? <Loading /> : <span>not found</span>
}

const RecipePage: React.FC<{ onSave: (recipe: Recipe | EnhancedRecipe, ignoreErrors?: boolean, republish?: boolean) => void }> = ({ onSave }) => {
  const handleSave = useCallback((recipe: Recipe, ignoreErrors?: boolean, republish?: boolean) => onSave(recipe, ignoreErrors, republish),[onSave])
  // @ts-ignore
  return <Switch>
    {/* @ts-ignore */}
    <Route path="/recipe/add/:type/:step?"><AddRecipeContent onSave={handleSave} /></Route>
    {/* @ts-ignore */}
    <Route path="/recipe/:recipeId">
      {/* @ts-ignore */}
      <QueryRecipe>
        {/* @ts-ignore */}
        {recipe => <Switch>
          {/* @ts-ignore */}
          <Route path="/recipe/:recipeId/revision/:revision1/:revision2?/:revision3?"><Revisions recipe={recipe} /></Route>
          {/* @ts-ignore */}
          <Route path="/recipe/:recipeId/:step?"><EditRecipe recipe={recipe} onSave={handleSave} /></Route>
        </Switch>}
      </QueryRecipe>
    </Route>
  </Switch>
}

const Recipes: React.FC = () => {
  const history = useHistory()
  const [recipes, setRecipes] = useState([] as Recipe[])
  const [snackbarProps, setSnackbar] = useState(undefined as { message: string, variant: 'success' | 'warning' | 'error' | 'info' } | undefined)
  const [dialogProps, confirmDelete] = useState({ open: false } as Omit<ConfirmDialogProps, 'title' | 'content'>)
  const [archived, setArchived] = useState(false);
  const [recipesLoading, setRecipesLoading] = useState(true)

  React.useEffect(() => {
    getRecipes(archived).then(
        function(response) {
          setRecipes(response.data.data);
          setRecipesLoading(false);
        }
    );
  },[archived, setRecipes, setRecipesLoading]);

  const handleArchived = useCallback((archived: any) => {
    setArchived(archived);
    setRecipesLoading(true);
    getRecipes(archived).then(
        function(response) {
          setRecipes(response.data.data);
          setRecipesLoading(false);
        }
    )
  },[setArchived, setRecipes, setRecipesLoading]);

  const handleSave = useCallback(async (recipe: Recipe, ignoreErrors?: boolean, republish?: boolean) => {
    recipe.status = getErrors(recipe).length > 0 && !ignoreErrors ? RecipeStatus.Error : RecipeStatus.Changed

    /*
      De interface stelt voor dat een recept de gepubliceerd status krijgt of behoud na het wijzigen
      (en opslaan) van een recept. De recept status wordt op CHANGED gezet indien de gebruiker opteert
      voor het uitvinken van het aankruisvak.
      (Zie ConfirmationStep regel 170)
     */
    if(recipe['republish']!==undefined) {
      if(recipe['republish'])
        recipe.status=RecipeStatus.Published;
      delete recipe['republish'];
    } else
      recipe.status=RecipeStatus.Published;

    if (!recipe.id) {
      const response=await post(sanitizeRecipe(recipe));
      recipe.id=response.data.data.id
      setRecipes(recipes => [...recipes, recipe])
    } else {
      let data=sanitizeRecipe(recipe);
      data['id']=recipe.id;
      const response=await post(data);
      const index = recipes.findIndex(r => r.id === recipe.id)
      index >= 0 && (recipes[index] = response.data.data)
      setRecipes(recipes => [...recipes])
    }
    history.push('/recipe')
    setSnackbar({ variant: 'success', message: 'Succesvol opgeslagen!' })
  }, [history, recipes])

  const handleTogglePublish = useCallback(async ({ id, status }: Recipe) => {
    if(status === RecipeStatus.Published)
      await unPublish(id);
    else
      await publish(id);
    const index = recipes.findIndex(r => r.id === id)
    index >= 0 && (recipes[index].status = status === RecipeStatus.Changed ? RecipeStatus.Published : RecipeStatus.Changed)
    setRecipes([...recipes])
  }, [recipes])

  const handlePublish = useCallback((ids: number[]) => {
    publishBatch(ids).then(
        function(response) {
          for(let id of ids) {
            const index=recipes.findIndex(r => r.id===id)
            if(index > -1) {
              recipes[index].status=RecipeStatus.Published;
            }
          }
          setRecipes(recipes => [...recipes])
        }
    )
  },[recipes])

  const handleUnpublish = useCallback((ids: number[]) => {
    unPublishBatch(ids).then(
        function(response) {
          for(let id of ids) {
            const index = recipes.findIndex(r => r.id === id)
            if(index>-1) {
              recipes[index].status = RecipeStatus.Changed;
              recipes[index].publishedBy = undefined;
              recipes[index].publishedDate = undefined;
            }
          }
          setRecipes(recipes => [...recipes])
        }
    )
  }, [recipes])

  const handleDelete = useCallback((ids: number[]) => {
    confirmDelete({
      open: true,
      onCancel: () => confirmDelete({ open: false }),
      onConfirm: async () => {
        await archiveBatch(ids);
        setRecipes(recipes => recipes.filter(recipe => recipe.id && ids.indexOf(recipe.id) < 0))
        confirmDelete({ open: false })
      }
    })
  }, [])

  const handleCopy = useCallback(async ({ id }: Recipe) => {
    let response=await getOne(id);
    const { remarksExternal, remarksInternal, codeExternal, ...copy } = normalizeRecipe(response.data.data)
    copy.recipeName = copy.recipeName + ' [KOPIE]'
    const encoded = await codec.compress(copy)
    const type = copy.strengthClass ? 'loge' : 'los'
    history.push(`recipe/add/${type}/overview?r=${encoded}`)
  }, [history])

  // @ts-ignore
  return <PageWithBreadcrumbs>
    {recipesLoading ? <Loading /> : <div></div>}
    {/* @ts-ignore */}
    <Switch>
      {/* @ts-ignore */}
      <Route path="/recipe" exact={true}>
        <FormGroup className={'activeSwitch'}>
          <FormControlLabel
              control={<UISwitch defaultChecked={false}  onChange={e => handleArchived(e.target.checked)} />} label="Gearchiveerd" />
        </FormGroup>
        <RecipesList recipes={recipes} onPublish={handlePublish} onUnpublish={handleUnpublish} onDelete={handleDelete} onCopy={handleCopy} onTogglePublish={handleTogglePublish} />
      </Route>
      {/* @ts-ignore */}
      <Route><RecipePage onSave={handleSave} /></Route>
    </Switch>
    <Snackbar open={Boolean(snackbarProps)} onClose={() => setSnackbar(undefined)} autoHideDuration={6000}>
      <CustomSnackbarContent {...snackbarProps} />
    </Snackbar>
    <ConfirmDialog {...dialogProps} title="Recepten archiveren" content="Weet u zeker dat u deze recepten wilt archiveren?" />
  </PageWithBreadcrumbs>
}

export default Recipes

function normalizeRecipe(recipe: Recipe) {
  const { id, status, revisions, ...rest } = recipe
  return {
    ...rest
  } as Recipe
}
